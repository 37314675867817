@font-face {
    font-family: Bebe-Neue;
    src: url(./fonts/BebasNeue.woff);
  }
@font-face {
    font-family: Bebe-Neue-Normal;
    src: url(./fonts/BebasNeue\ Regular.woff);
  }

@font-face {
    font-family: Assistant;
    src: url(./fonts/Assistant-Regular.woff);
}
@font-face {
    font-family: Assistant-Light;
    src: url(./fonts/Assistant-Light.woff);
}
body{
    background-color: white;
    margin: 0;
    overflow-x: hidden;
}
.bgField{
    width: 100%;
}
*{
    font-family: Assistant;
}
h1,h2,h3,h4,h5,h6{
    font-family: Bebe-Neue;
}
.hero-section img{
    filter: brightness(60%);
}
.grass{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    
    z-index: 100;
}
.heroText{
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    max-height: 100vh;
    right: 0;
    

}
.text-10{
    font-size: 10rem;
}
.heroText p{
    font-size: 20px;
    font-family: Assistant-Light;
   
}
.bgField:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0), black);
 }
.navbar{
    position: fixed;
    width: 100%;
    z-index: 1000;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
}
.navbar-toggler, .navbar-toggler:focus{
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
.nav-link h4{
    margin-bottom: 0;
    color: white;
    font-family: Bebe-Neue-Normal;
    
    
}
.blur {
    width: 100vw;
    
    height: 30vh;
    background-color: white;
    position: absolute;
    bottom: -30vh;
    box-shadow: 0 0 70px 90px white;

    z-index: 100;
}
.accomplishments{
    position: fixed;
    bottom: 10px;
    z-index: 200;

}
.nav-link {
    padding: 0 2rem !important;
}
.scrolled{
    background-color: white;
    box-shadow: none !important;
    
}
.h-100vh{
    height: 100vh;
}
.stats{
    background-color: white;
    
}
.rotStats{
    rotate: 90deg;
    height: 50px;
    left: -4.5rem;
    position: relative;
    width: max-content;

}
.navbar-brand img{
    width: 80px;
    height: auto;
    filter: brightness(0) invert(1);
}
.scrolled .navbar-brand img{
    width: 80px;
    height: auto;
    filter: none;
}
.scrolled .nav-link h4{
    color: black;
}

.sections{
    padding: 1rem 3rem;
    background-color: white;
    z-index: 200;
    position: relative;
}
.who-we-are h1{
   
    font-size: 10rem;
    font-weight: 700;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: text;
    filter: brightness(70%);

    -webkit-background-clip: text;
    color: transparent;
    background-image: url(./img/bg.png);
}
.who-we-are p{
  
    text-align: justify;
    text-justify: newspaper;
}
.moreButton{
    color: #4a4412;
    border: 1px solid #4a4412;
    width: max-content;
    padding: 0.5rem 1.5rem;
    text-decoration: none;
}
.moreButton:hover{
    background-color: #9A4403;
    color: white;
    border-color: #9A4403;
}
.kemassa-green{
   color: #4a4412; 
}
.kemassa-orange{
    color: #9A4403;
}
.mxh-80vh{
    max-height: 80vh;
    width: auto;
}
.vid-style{
    filter: brightness(70%);
 


}
.accomplishments img{
   
    width: 70px;
    height: auto;
    filter: brightness(0) invert(1);
}
.accomplishments p{
    font-size: 85%;
    margin-bottom: 0;
    margin-left: 15px;
}
.accomplishments .col-6{
    
    scale: 0.9;

}
.footer{
    background-color: #4a4412;
}
.footer img{
    width: 100px;
}
.footer .socials a{
    margin: 0 15px;
}
.error-input input{
    border: 1px solid red;
}
.sideKem{
    transform: rotate(90deg);
    
    position: absolute;
    right: -150px;
    top: 10vh;
    opacity: 0.1;
    z-index: 10000;
}
.admin .nav-link{
    padding: 2rem 2rem !important;
}
.admin .nav-link svg{
    margin-right: 0.8rem;
}
.who-we-are{
    z-index: 203;
}
.mobile-sec{
    max-height: 90vh;
    overflow: hidden;
}
.white-img{
    filter: brightness(0) invert(1);

}
.bg-admin{
    background-image: url(./img/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.filter{
    background-color: rgba(0, 0, 0, 0.27);
    filter: brightness(70%);
}
.servicesImg{
    max-height: 80vh;
    display: block ;
    margin: auto;
}
#mobButton:hover{
    background-color: #9A4403 !important;
    border-color: #9A4403;
}
/* .contact{
    background-color: #9A4403;
    overflow: hidden;
} */
.w-max-content{
    width: max-content !important;
}
.mw-100px{
    max-width: 100px;
}
html{
    width: 100vw;
    overflow-x: hidden;
}
.contactImg{
    width: 50%;
    top: -2rem;
    position: absolute;
    clip-path: polygon(100%  100%, 100% 00%, 0% 5%, 30% 100%);


}
.panel * {
    position: relative;
    color: white;
}
.panel.active{
    width: 41.66666667%;
}
.contact input, .contact textarea, .contact button{
    padding: 0.25rem 0.75rem;
    border-radius: 3px;
    outline: none;
    border: none;
}
.contact button{
    background-color: rgba(0, 0, 0, 0);
}

.w-45{
    width: 48%;
}
.accomp-rs{
    display: none;
}
.logoAdmin{
    max-width: 100px;
}
.outline-btn{
    background-color: white;
    border: 1px solid #9A4403;
    color: #9A4403;
    outline: none;
    width: max-content;
}
 
.addButt{
    background-color: white;
    border: 1px solid #4a4412;
    color: #4a4412;
    outline: none;
    width: max-content !important;
    margin-left: auto;

}
.admin .nav-link.active{
    color: #4a4412;
    font-weight: 700;
}
.removeButt{
    background-color: white;
    border: 1px solid red;
    color: red;
    outline: none;
    width: max-content;

}
@media (max-width: 991px) {

    .px-0-rs{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .border-white-rs{
        border: 1px solid white !important;
    }
    .border-white-rs:hover{
        background-color: white !important;
        color: #4a4412 !important;
    }
    .contact{
        margin-bottom: 1rem;
    }

    
.navbar-collapse {
    width: 100vw;
    position: absolute;
    top: 80px;
    left: 0;
    background-color: #fff;
    height: 100vh;
    flex-grow: initial !important;
}
.navbar-collapse .nav-link{
    padding-top: 1rem !important ;
    color: #4a4412;
    padding-bottom: 1rem !important;
}
.navbar-collapse .nav-link h4{
    color: #4a4412 !important;
}
    .grass{
        display: none;
    }
    .heroText p{
        font-size: 10px;
    }
    .accomp-rs{
        display: block;

    }
    .accomp-rs img{
        max-width: 40px;
        filter: brightness(0) invert(1);

    }
   
    .accomp-rs .d-flex{
        display: flex !important;
        margin-bottom: 0.35rem;
    }

    .text-10{
        font-size: 3rem;
    }
    .bgField {
     
        height: 100%;
        width: auto;}
    .heroText{
        z-index: 110;
        margin-top: 1rem;
    }
    .rotStats, .sideKem{
        display: none;
    }
    .footer .col-sm-7, .footer .col-12{
        order: 1;
    }
    
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
    .d-flex{
        display: block !important;
    }
    .spec-flex{
        display: flex !important;
    }
    .sections{
        padding: 1rem !important;
    }
    .panel.active{
        width: 100%;
    }
    .mobile-sec .col-4{
        margin-bottom: 2rem;
    }
    .contact .w-45{
        width: 100%;
        margin-bottom: 0.5rem;
    }
    .accomplishments{
        display: none !important;
    }
    .hero-section{
       
        height: 100vh;
    }
    .heroText{
        display: flex !important;
    }
    .heroText p{
        width: 90% !important;
        font-size: large;
    }
    .accomp-rs p{
        font-size: small;
        margin-bottom: 0;
    }
    .panel{
        min-height: 30vh !important;
        margin-bottom: 0.35rem;
    }
}